import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { ReactComponent as IconLogo } from 'assets/svg/logo.svg'

import {
  AccessToken,
  AuthDriver,
  LoginRequest,
  LoginResponse,
  ResetPasswordRequest,
} from 'services/auth'
import { Modal, Toast, Button, Input, Loader } from 'components'
import { useToggle, useUserInfo } from 'shared/hooks'

import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { loginSchema, resetPasswordSchema } from '../../schemas'

import styles from './Login.module.scss'
import { ToastType } from 'components/Toast/types'
import { handleSetCookies } from 'utilities/auth'

export type LoginRouteParam = {
  sessionClosed: boolean
}

type ToastInfo = {
  type?: ToastType
  message: string
}

const Login = () => {
  const navigate = useNavigate()
  const invalidCredentials = useToggle()
  const resetPassword = useToggle()
  const serviceError = useToggle()
  const toast = useToggle()
  const loader = useToggle()
  const location = useLocation()
  const { fetchUserInfo } = useUserInfo()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    mode: 'onBlur',
    resolver: joiResolver(loginSchema),
  })

  const {
    reset,
    register: resetPasswordRegister,
    handleSubmit: resetPasswordRegisterHandleSubmit,
    formState: { errors: resetPasswordErrors, isValid: resetPasswordIsValid },
  } = useForm<ResetPasswordRequest>({
    mode: 'onSubmit',
    resolver: joiResolver(resetPasswordSchema),
  })

  const [toastInfo, setToastInfo] = useState<ToastInfo>({
    message: '',
  })

  useEffect(() => {
    if (location.state?.sessionClosed) {
      setToastInfo({
        message: 'Sua sessão foi encerrada. Tente efetuar o login novamente.',
      })
      toast.show()
    }
  }, [location.state])

  const handleLogin = async (data: LoginRequest) => {
    loader.show()
    try {
      const loginResponse: LoginResponse = await AuthDriver.login(data)
      const decodedToken = jwtDecode(loginResponse.access_token) as AccessToken

      handleSetCookies({
        userId: decodedToken.user.id,
        accessToken: loginResponse.access_token,
        refreshToken: loginResponse.refresh_token,
      })

      navigate(location.state?.from || '/', { replace: true })
      fetchUserInfo()
    } catch (error) {
      if (error?.response?.status === 401) {
        invalidCredentials.show()
      } else {
        serviceError.show()
      }
    } finally {
      loader.hide()
    }
  }

  const handleResetUserPassword = async (data: ResetPasswordRequest) => {
    loader.show()
    try {
      await AuthDriver.resetPassword(data.email)
      resetPassword.hide()
      setToastInfo({
        message: 'Recuperação de senha solicitada. Confira o seu email.',
      })
      reset()
      toast.show()
    } catch (error) {
      setToastInfo({
        type: 'alert',
        message:
          'Erro ao gerar o link de recuperação de senha. Tente novamente.',
      })
      toast.show()
    } finally {
      loader.hide()
    }
  }

  const handleHideToast = () => {
    toast.hide()
    setToastInfo({ message: '' })
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <IconLogo />
        <form onSubmit={handleSubmit(handleLogin)}>
          <div>
            <Input
              id="email-input"
              label="Usuário"
              placeholder="Usuário"
              {...register('login')}
              errorMessage={errors?.login?.message}
            />
            <Input
              id="password-input"
              password
              label="Senha"
              placeholder="••••••••"
              {...register('password')}
              errorMessage={errors?.password?.message}
            />
          </div>
          <div className={styles.actions}>
            <Button
              type="tertiary"
              buttonTitle="Esqueci minha senha"
              onClick={() => {
                resetPassword.show()
              }}
            />
            <Button buttonTitle="Entrar" type="primary" htmlType="submit" />
          </div>
        </form>
      </div>

      <Loader isVisible={loader.isVisible} />

      <Toast
        isVisible={toast.isVisible}
        onClose={handleHideToast}
        type={toastInfo.type}
      >
        {toastInfo.message}
      </Toast>

      <Modal
        simple
        title="Usuário ou senha inválidos"
        isVisible={invalidCredentials.isVisible}
        onClose={() => invalidCredentials.hide()}
      >
        <p className={styles.errorModalText}>
          {`Insira suas credenciais novamente ou clique em "Esqueci minha senha".`}
        </p>
        <Modal.Footer>
          <Button
            autofocus
            width="172px"
            buttonTitle="Fechar"
            onClick={() => invalidCredentials.hide()}
            type="primary"
            id="close-invalid-credentials-button"
          />
        </Modal.Footer>
      </Modal>

      <Modal
        simple
        title="Erro no servidor"
        isVisible={serviceError.isVisible}
        onClose={() => serviceError.hide()}
      >
        <p className={styles.errorModalText}>
          Não foi possível realizar a ação devido a um erro em nossos
          servidores.
        </p>
        <p className={styles.errorModalText}>
          Tente novamente mais tarde ou entre em contato com o suporte de TI.
        </p>
        <Modal.Footer>
          <Button
            width="172px"
            buttonTitle="Fechar"
            onClick={() => serviceError.hide()}
            type="primary"
            id="close-invalid-credentials-button"
          />
        </Modal.Footer>
      </Modal>

      <Modal
        size="sm"
        id="reset-password-modal"
        title="Recuperar senha"
        isVisible={resetPassword.isVisible}
        onClose={() => resetPassword.hide()}
      >
        <p className={styles.resetModalText}>
          Informe o endereço de email que você costuma utilizar para fazer login
          no sistema. Um link será enviado para o seu email para realizar a
          redefinição de senha.
        </p>
        <form
          onSubmit={resetPasswordRegisterHandleSubmit(handleResetUserPassword)}
        >
          <Input
            {...resetPasswordRegister('email')}
            id="reset-password-input"
            label="Endereço de email"
            placeholder="email@mail.com.br"
            errorMessage={resetPasswordErrors?.email?.message}
          />
          <div className={styles.resetPasswordModalForm}>
            <Button
              width="172px"
              id="confirm-service-error-button"
              buttonTitle="Recuperar"
              type="primary"
              htmlType="submit"
              disabled={!resetPasswordIsValid}
            />
            <Button
              width="172px"
              id="cancel-service-error-button"
              buttonTitle="Cancelar"
              type="secondary"
              disabled={false}
              onClick={() => resetPassword.hide()}
            />
          </div>
        </form>
      </Modal>
    </div>
  )
}

export default Login
